<template>
  <div class="profile-password pl-lg-9 pa-sm-7 pa-3">
    <div class="text-h6 font-weight-bold mb-7">Change password</div>
    <v-text-field
      class="field46"
      v-model="curPass"
      placeholder="Current password"
      :error-messages="passwordCurErrors"
      :type="showCur ? 'text' : 'password'"
      :append-icon="showCur ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="showCur = !showCur"
      outlined
      dense
      required
      color="primary"
      height="46"
    ></v-text-field>
    <v-text-field
      class="field46 mt-n2"
      v-model="newPass"
      placeholder="New password"
      :error-messages="passwordErrors"
      :type="show ? 'text' : 'password'"
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="show = !show"
      outlined
      dense
      required
      color="primary"
      height="46"
    ></v-text-field>
    <div>
      <v-btn @click="update" width="176" large depressed color="primary">Save</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      curPass: '',
      newPass: '',
      showCur: false,
      show: false,
      error: [],
    };
  },
  methods: {
    notifi() {
      this.$notify({ title: 'Done!', message: 'Password was successfully updated', horizontalAlign: 'center', verticalAlign: 'top' });
    },
    async update() {
      this.error = [];
      const data = new Object();
      data.current_password = this.curPass;
      data.new_password = this.newPass;
      await this.$store
        .dispatch('newPasswordProfile', data)
        .then(() => {
          this.notifi();
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    passwordErrors() {
      const errors = [];
      this.error.find((item) => item == 'new_password__required') && errors.push('Please enter your new password');
      this.error.find((item) => item == 'new_password__invalid') &&
        errors.push('New password should contains at least one digit, one symbol and be at least 8 characters long');
      this.error.find((item) => item == 'new_password__weak') && errors.push('Sorry, provide new password weak');
      return errors;
    },
    passwordCurErrors() {
      const errors = [];
      this.error.find((item) => item == 'current_password__required') && errors.push('Please enter your current password');
      this.error.find((item) => item == 'current_password__invalid') && errors.push('Provided current password is not valid');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.profile-password {
  max-width: 460px;
  width: 100%;
}
</style>
