<template>
  <div class="profile">
    <div class="text-h6 font-weight-bold">My profile</div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="d-flex wrap">
        <v-card color="item-menu pb-2" flat>
          <div class="py-8 px-4 d-flex">
            <v-avatar color="gray lighten-2" size="52">
              <v-img v-if="data.picture != null" :src="data.picture"></v-img>
              <div v-else class="d-flex align-center">
                <v-img class="gray-filter" contain width="36" height="36" src="@/assets/icon/user.svg"></v-img>
              </div>
            </v-avatar>
            <div class="ms-3">
              <div class="text-h6 font-weight-bold">{{ data.first_name }} {{ data.last_name }}</div>
              <div class="text--text text-capitalize">{{ data.role }}</div>
            </div>
          </div>
          <div
            v-for="(item, i) in menuItems"
            :key="item"
            :class="link == i + 1 ? 'gray lighten-4 pl-3 pr-5' : 'px-5'"
            @click="link = i + 1"
            class="item-menu_item link"
          >
            <div>{{ item }}</div>
          </div>
        </v-card>
        <v-divider style="z-index: 1" vertical></v-divider>
        <div class="width100">
          <div v-if="!data.is_email_confirmed && link == 1" class="d-flex flex-wrap align-center msg">
            <v-icon color="warning">mdi-alert-circle-outline</v-icon>
            <div class="ml-4 text-body-2">Email is not confirmed</div>
            <v-spacer></v-spacer>
            <v-btn @click="resend" outlined width="116">Resend</v-btn>
          </div>
          <div>
            <v-expand-transition>
              <div v-if="link == 1">
                <Name />
              </div>
            </v-expand-transition>
            <v-expand-transition>
              <div v-if="link == 2">
                <Password />
              </div>
            </v-expand-transition>
            <v-expand-transition>
              <div v-if="link == 3">
                <Security />
              </div>
            </v-expand-transition>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Name from '@/components/profile/Name';
import Password from '@/components/profile/Password';
import Security from '@/components/profile/Security';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    Name,
    Password,
    Security,
  },
  data() {
    return {
      link: 1,
      menuItems: ['Account', 'Change Password', 'Security'],
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'confirm') {
        this.$notify({
          title: 'Done!',
          message: 'Email confirmation was sent successfully',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error.find((item) => item == 'confirmation__too_often')) {
        this.$notify({
          title: 'Warning!',
          message: 'Sorry, but email confirmation requests limit exceeded. Try again later',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (this.error.find((item) => item == 'email__already_confirmed')) {
        this.$notify({
          title: 'Warning!',
          message: 'Email is already confirmed',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    async resend() {
      this.error = [];
      await this.$store
        .dispatch('resendEmailProfile')
        .then(() => {
          this.notifi('confirm');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          this.notifi();
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.profile;
    },
  },
};
</script>

<style lang="scss">
.profile {
  .msg {
    width: 100%;
    padding: 12px 100px;
    background: var(--v-warning-lighten5);
    border: 1px solid var(--v-warning-base);
    margin-left: 1px;
    .v-btn {
      background: white;
      .v-btn__content {
        font-size: 14px !important;
      }
    }
  }
  @media screen and (max-width: 1263px) {
    .msg {
      padding: 12px 30px;
    }
  }
  @media screen and (max-width: 599px) {
    .msg {
      padding: 12px;
    }
  }
}
// @media screen and (max-width: 959px) {
//   .profile-menu {
//     position: static;
//   }
// }
// @media screen and (max-width: 599px) {
//   .profile-menu {
//     height: initial;
//   }
// }
</style>
