<template>
  <div class="profile-name item-content">
    <div class="d-flex flex-md-nowrap flex-wrap">
      <v-card tile flat max-width="460" class="pl-lg-9 pa-sm-7 pa-3 width100">
        <div class="text-h6 font-weight-bold mb-5">Account details</div>
        <v-text-field
          class="field46"
          v-model="data.first_name"
          placeholder="First name"
          :error-messages="firstnameErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-text-field
          class="field46 mt-n2"
          v-model="data.last_name"
          placeholder="Last name"
          :error-messages="lastnameErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-text-field
          class="field46 mt-n2"
          v-model="data.email"
          placeholder="Email"
          :error-messages="emailErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <div>
          <v-btn @click="update" width="176" large depressed color="primary">Save</v-btn>
        </div>
      </v-card>
      <div class="ml-auto mr-lg-0 mr-auto px-5 mt-7">
        <div class="text-h6 font-weight-bold mb-5">Profile photo</div>
        <div class="link" @click="changeAvatar">
          <v-card v-if="!data.picture && !image" class="foto-card d-flex align-center" flat width="174" height="174">
            <v-img class="gray-filter" contain height="120" src="@/assets/icon/user.svg"></v-img>
          </v-card>
          <v-img cover v-else height="174" max-width="174" :src="image || data.picture"></v-img>
        </div>
        <v-btn :disabled="!file" @click="updatePhote" outlined width="176" large class="d-block text-body-2 mx-auto mt-6">
          Upload Photo
        </v-btn>
        <v-btn
          :disabled="!data.picture"
          @click="deletePhote"
          text
          width="176"
          large
          class="d-block text-body-2 mx-auto mt-1 mb-5"
          color="primary"
        >
          Remove Photo
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      image: '',
      file: '',
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'updatePhoto') {
        this.$notify({
          title: 'Done!',
          message: 'Your profile picture was successfully updated',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({ title: 'Done!', message: 'Profile was successfully updated', horizontalAlign: 'center', verticalAlign: 'top' });
      }
    },
    changeAvatar() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async updatePhote() {
      const formData = new FormData();
      formData.append('picture', this.file);
      await this.$store.dispatch('setPhoto', formData).then(() => {
        this.file = '';
        this.image = '';
        this.notifi('updatePhoto');
      });
    },
    async deletePhote() {
      await this.$store.dispatch('deletePhoto').then(() => {
        this.file = '';
        this.image = '';
        this.$store.dispatch('getProfile');
      });
    },
    async update() {
      this.error = [];
      const data = new Object();
      data.first_name = this.data.first_name || '';
      data.last_name = this.data.last_name || '';
      data.email = this.data.email || '';
      await this.$store
        .dispatch('updateProfile', data)
        .then(() => {
          this.notifi('update');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.profile;
    },
    firstnameErrors() {
      const errors = [];
      this.error.find((item) => item == 'first_name__required') && errors.push('Please enter your first name');
      this.error.find((item) => item == 'first_name__invalid') && errors.push('Provided first name is not valid');
      return errors;
    },
    lastnameErrors() {
      const errors = [];
      this.error.find((item) => item == 'last_name__required') && errors.push('Please enter your last name');
      this.error.find((item) => item == 'last_name__invalid') && errors.push('Provided last name is not valid');
      return errors;
    },
    emailErrors() {
      const errors = [];
      this.error.find((item) => item == 'email__required') && errors.push('Please enter your email address');
      this.error.find((item) => item == 'email__invalid') && errors.push('Provided email address is not valid');
      this.error.find((item) => item == 'email__exists') &&
        errors.push('Provided email address is already registered. Please use another one');
      this.error.find((item) => item == 'email__update_too_often') &&
        errors.push('Sorry, but you are trying to update email too often. Please try again later');
      return errors;
    },
  },
};
</script>
