<template>
  <div class="profile-security">
    <div class="item-content d-flex align-center pl-lg-9 pa-sm-7 pa-3">
      <v-card max-width="500" tile flat class="me-4">
        <div class="text-h6 font-weight-bold">Two factor authentication</div>
        <div class="text--text mt-2">
          Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log in
        </div>
      </v-card>
      <v-spacer></v-spacer>
      <v-btn v-if="!data['2fa_enabled'] && !window" @click="window = true" depressed large width="176" color="primary">Enable</v-btn>
      <v-btn v-if="data['2fa_enabled'] && step != 4" @click="modal = true" depressed large width="127" color="error">Disable</v-btn>
    </div>
    <v-divider v-if="data['2fa_enabled'] && step != 4"></v-divider>
    <div v-if="data['2fa_enabled'] && step != 4" class="item-content pl-lg-9 px-sm-7 py-sm-5 pa-3">
      <div class="d-flex align-center">
        <v-card max-width="500" tile flat class="me-4">
          <div class="text-h6 font-weight-bold">
            Recovery Codes
            <span class="pl-2"
              ><v-icon @click="show = !show">{{ show ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon></span
            >
          </div>
          <div class="text--text mt-1">
            Treat your recovery codes with the same level of security as you would your password. We recommend saving them with a password
            manager such as Lastpass, 1Pasword or Keeper.
          </div>
        </v-card>
        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" outlined depressed large width="127">
              Actions <v-icon>{{ attrs ? 'mdi-menu-down' : 'mdi-menu-up' }}</v-icon>
            </v-btn>
          </template>
          <v-card flat outlined class="px-3 py-1">
            <div @click="request(item.title)" v-for="(item, i) in items" :key="item.title" class="link">
              <v-divider v-if="i"></v-divider>
              <div class="d-flex align-center my-2">
                <v-img contain max-width="16" height="16" :src="getIcon(item.icon)"></v-img>
                <div class="ml-2 text-body-2">{{ item.title }}</div>
              </div>
            </div>
          </v-card>
        </v-menu>
      </div>
      <v-card v-if="show" flat outlined color="mt-5 py-sm-5 px-sm-4 py-3 px-2">
        <v-row>
          <v-col cols="6" sm="3" v-for="(item, i) in codes.codes" :key="item + i" class="font-weight-bold py-0">
            {{ item }}
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-window v-if="window" v-model="step" class="item-content pl-lg-2">
      <v-window-item :value="1">
        <v-card outlined flat color="ml-7 mr-12 mb-4">
          <div class="py-sm-5 px-sm-4 py-3 px-2">
            <div class="d-flex">
              <v-card flat max-width="550">
                <div class="font-weight-bold">Recovery Codes</div>
                <div class="text--text mt-1">
                  Treat your recovery codes with the same level of security as you would your password. We recommend saving them with a
                  password manager such as Lastpass, 1Password, or Keeper.
                </div>
              </v-card>
              <v-spacer></v-spacer>
              <v-card flat outlined rounded="lg" class="d-flex align-center" height="38">
                <v-btn depressed icon @click="copy" width="48">
                  <img src="@/assets/icon/files.svg" alt="copy" />
                </v-btn>
                <v-divider vertical></v-divider>
                <v-btn depressed icon @click="download" width="48">
                  <img src="@/assets/icon/download.svg" alt="save" />
                </v-btn>
              </v-card>
            </div>
            <v-row class="mt-7 mb-3">
              <v-col cols="6" sm="3" v-for="(item, i) in codes.codes" :key="item + i" class="font-weight-bold py-0">
                {{ item }}
              </v-col>
            </v-row>
          </div>
          <v-divider></v-divider>
          <v-card flat class="d-flex align-start pa-4 pb-5" max-width="500">
            <v-icon color="error">mdi-alert-circle-outline mdi-18px</v-icon>
            <div class="error--text text-caption ml-2">
              If you lose access to your 2FA application/device and don’t have the recovery codes - you will lose access to your account.
            </div>
          </v-card>
        </v-card>
        <div class="mb-6 mx-7">
          <v-btn class="mr-5" @click="window = false" outlined large width="116">Cancel</v-btn>
          <v-btn @click="step2" depressed color="primary" large width="176">Next</v-btn>
        </div>
      </v-window-item>
      <v-window-item :value="2">
        <v-card outlined flat color="ml-7 mr-12 mb-4 pa-sm-7 pa-3 pb-sm-12">
          <div class="font-weight-bold">2FA application/device</div>
          <div class="text--text mt-1">Scan the QR code with your 2FA application/device.</div>
          <div class="d-flex flex-wrap justify-space-around align-center">
            <v-img max-width="220" class="d-flex align-center mt-8" src="@/assets/icon/border.svg">
              <v-img max-width="180" class="mx-auto" :src="qr.qr_code"></v-img>
            </v-img>
            <v-card tile flat outlined max-width="220" height="220" class="px-3 py-7 mt-8">
              <div class="text--text text-body-2 text-center mb-5">Or enter the code manualy in your application</div>
              <div class="text-wrap font-weight-bold text-center">{{ qr.text_code }}</div>
            </v-card>
          </div>
        </v-card>
        <div class="mb-6 mx-7">
          <v-btn class="mr-5" @click="step = 1" outlined large width="116">Cancel</v-btn>
          <v-btn @click="step = 3" depressed color="primary" large width="176">Next</v-btn>
        </div>
      </v-window-item>
      <v-window-item :value="3">
        <v-card outlined flat color="ml-7 mr-12 mb-4 pa-sm-7 pa-3 pb-3">
          <div class="font-weight-bold">2FA application/device</div>
          <div class="text--text mt-1">Enter the code</div>
          <v-text-field
            style="max-width: 400px"
            class="field46 mt-5"
            v-model="code"
            placeholder="Code"
            :error-messages="codeErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
        </v-card>
        <div class="mb-6 mx-7">
          <v-btn class="mr-5" @click="step = 2" outlined large width="116">Cancel</v-btn>
          <v-btn @click="enable" depressed color="primary" large width="176">Next</v-btn>
        </div>
      </v-window-item>
      <v-window-item :value="4">
        <div class="ml-7 mr-12 mb-4">
          <v-card outlined flat color="mb-4 px-4 pt-5 pb-8">
            <div class="font-weight-bold">2FA is enabled</div>
            <div class="text--text">
              Now you will need to enter OTP as a second factor during the sing-in. Also, keep the recovery codes in a safe place.
            </div>
          </v-card>
          <v-btn @click="(window = false), (step = 1)" depressed large width="176" color="primary">Done</v-btn>
        </div>
      </v-window-item>
    </v-window>
    <v-dialog v-model="modal" max-width="480">
      <v-card flat outlined class="pb-6 px-4">
        <div class="d-flex my-3">
          <div class="text-h6 font-weight-bold">Do you really want to disable the 2FA sign-in?</div>
          <v-btn icon @click="modal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="text-body-2 text--text mb-5">You account securing will be reduced without 2FA.</div>
        <div class="text-end">
          <v-btn @click="disable" depressed width="106" height="30" class="primary text-body-2">Confirm</v-btn>
          <v-btn @click="modal = false" outlined class="ms-3 text-body-2" width="106" height="30">Cancel</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalCodes" max-width="480">
      <v-card flat outlined class="pb-6 px-4">
        <div class="d-flex my-3">
          <div class="text-h6 font-weight-bold">Do you really want to generate new recovery codes?</div>
          <v-btn icon @click="modalCodes = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <div class="text-body-2 text--text mb-5">The previous codes will not work after the new will be generated.</div>
        <div class="text-end">
          <v-btn @click="generate" depressed width="106" height="30" class="primary text-body-2">Confirm</v-btn>
          <v-btn @click="modalCodes = false" outlined class="ms-3 text-body-2" width="106" height="30">Cancel</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: false,
      modalCodes: false,
      window: false,
      show: false,
      step: 1,
      code: '',
      items: [
        { title: 'Generate new', icon: 'update' },
        { title: 'Copy', icon: 'files' },
        { title: 'Download', icon: 'download' },
      ],
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getCodes');
  },
  methods: {
    notifi(btn) {
      if (btn == 'copy') {
        this.$notify({
          title: 'Done!',
          message: 'Recovery codes were copied to your clipboard',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'copyError') {
        this.$notify({ title: 'Error!', message: 'Sorry, error copied', type: 'error', horizontalAlign: 'center', verticalAlign: 'top' });
      } else if (btn == 'generate') {
        this.$notify({
          title: 'Done!',
          message: 'New recovery codes. Old codes doesn’t work anymore.',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    getIcon(icon) {
      return require(`@/assets/icon/${icon}.svg`);
    },
    request(i) {
      if (i == 'Copy') {
        this.copy();
      } else if (i == 'Download') {
        this.download();
      } else if (i == 'Generate new') {
        this.modalCodes = true;
      }
    },
    copy() {
      const string = this.codes.codes.join('\n');
      this.$copyText(string)
        .then(() => {
          this.notifi('copy');
        })
        .catch(() => {
          this.notifi('copyError');
        });
    },
    async download() {
      await this.$store.dispatch('downloadCodes');
    },
    async generate() {
      await this.$store.dispatch('generate').then(() => {
        this.modalCodes = false;
        this.notifi('generate');
      });
    },
    async step2() {
      await this.$store.dispatch('step2').then(() => {
        this.step = 2;
      });
    },
    async enable() {
      await this.$store
        .dispatch('enable', this.code)
        .then(() => {
          this.step = 4;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async disable() {
      await this.$store.dispatch('disable').then(() => {
        this.modal = false;
      });
    },
  },
  computed: {
    data() {
      return this.$store.getters.profile;
    },
    codes() {
      return this.$store.getters.codes;
    },
    qr() {
      return this.$store.getters.qr;
    },
    codeErrors() {
      const errors = [];
      this.error.find((item) => item == 'code__required') && errors.push('Please enter OTP from your TOTP application or device');
      this.error.find((item) => item == 'code__invalid') && errors.push('Provided OTP is not correct. Please check and try again');
      this.error.find((item) => item == 'otp__required') && errors.push('Please enter OTP from your TOTP application or device');
      this.error.find((item) => item == 'otp__invalid') && errors.push('Provided OTP is not correct. Please check and try again');
      this.error.find((item) => item == 'recovery_codes__not_generated') && errors.push('Sorry, recovery codes not generated');
      this.error.find((item) => item == 'secret__not_generated') && errors.push('Sorry, secret not generated');
      this.error.find((item) => item == 'action__limit_exceeded') && errors.push('Sorry, action limit exceeded');
      this.error.find((item) => item == 'action__not_allowed') && errors.push('Sorry, action not allowed');
      this.error.find((item) => item == 'otp__wrong') && errors.push('Provided OTP is not correct. Please check and try again');
      return errors;
    },
  },
};
</script>

<style scoped>
.select-item {
  min-height: 30px !important;
}
</style>
